<template>
  <div
    class="desktopLayoutClass"
    style="background-color: #f5faff;z-index: 0;height: 100vh"
  >
    <v-app style="background-color: #f5faff;z-index: 0;">
      <div
        v-show="show_header"
        class="header"
        style="
         display: flex;
         flex-direction: row;
         padding-top: 10px;
         padding-left: 5px;
         "
      >
        <div style="display: flex; flex-direction: row; width: 100%">
          <div
            style="width: 10%; padding-top: 6px"
            align="left"
            v-on:click="redirectToHomePage()"
          >
            <img
              src="https://img.icons8.com/material-outlined/20/000000/left.png"
            />
          </div>
          <div style="width: 90%" align="left">
            <span style="font-weight: 600; font-size: 15pt"
              >{{ $i18n.locale == 'en' ? "Video Consultation" : "ဗီဒီယိုဖြင့်တိုင်ပင်ဆွေးနွေးခြင်း" }}</span
            >

          </div>
        </div>
      </div>
      <!-- Dialog Start -->
      <div>
        <v-row justify="center">
          <v-dialog v-model="auth_error_enable" persistent max-width="290">
            <v-card>
              <v-card-title style="word-break:normal">
                {{ $t("Warning_Message.Unauthorized") }}
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="green darken-1"
                  text
                  @click="closeDialogUnauthorized"
                >
                  {{ $t("Warning_Message.Ok") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>

      <div>
        <v-row justify="center">
          <v-dialog v-model="appLevelCheckModal" persistent max-width="290">
          <v-card>
            <v-card-title style="word-break:normal">
            <p>{{ $t("Warning_Message.AppUpdatePromptVideoCall") }}</p>
            </v-card-title>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" depressed @click="closeAppLevelCheckModal">
              {{$t("Warning_Message.Ok")}}
            </v-btn>
            </v-card-actions>
          </v-card>
          </v-dialog>
        </v-row>
      </div>

      <div>
        <v-row justify="center">
          <v-dialog
            v-model="appointmentTimeOverModal"
            persistent
            max-width="290"
          >
            <v-card>
              <v-card-title style="word-break:normal">
                {{ $t("Warning_Message.Teleconsultation_Time_Is_Up") }}
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="green darken-1"
                  text
                  @click="closeDialogAppointmentTimeOverModal"
                >
                  {{ $t("Warning_Message.Ok") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>

      <div>
        <v-row justify="center">
          <v-dialog
            v-model="appointmentStartMessageModal"
            persistent
            max-width="290"
          >
            <v-card>
              <v-card-title style="word-break:normal">
                {{
                  $t(
                    "Warning_Message.Please_Join5Minutes_Before_Appointment_Time"
                  )
                }}
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="green darken-1"
                  text
                  @click="closeDialogAppointmentStartMessageModal"
                >
                  {{ $t("Warning_Message.Ok") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>

      <div>
        <v-row justify="center">
          <v-dialog v-model="error_enable" persistent max-width="290">
            <v-card>
              <v-card-title style="word-break:normal">
                {{ $t("Warning_Message.Something_Went_Wrong") }}
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="closeDialogError">
                  {{ $t("Warning_Message.Ok") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>
      <!-- Dialog Close -->
      <div v-if="executeSecondTimer">
        <p style="color:red;border:2px;" class="timerClass">
          {{ countDownTimer }}
        </p>
        <v-row>
          <v-col cols="12" sm="12" style="padding: 0px;">
            <!-- <iframe
   :src="appointment_url"
   allow="camera; microphone; fullscreen; speaker; display-capture" style="overflow:hidden;height:90vh;width:100%"
   ></iframe> -->
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <div>
          <v-row> </v-row>
          <v-row>
            <v-col cols="12" sm="12" style="padding: 0px;">
              <div class="timerClass" style="left:5%; z-index:999">
                <p class="timmerText">{{ countDownTimer }}</p>
              </div>
              <!-- <iframe
      :src="appointment_url"
      allow="camera; microphone; fullscreen; speaker; display-capture" style="overflow:hidden;height:90vh;width:100%"
      ></iframe> -->
              <!-- <whereby-embed :room="appointment_url"  audio=on video=on /> -->
              <div id="videos">
                <div id="subscriber" class="sub_class"></div>
                <div id="publisher" class="pub_class"></div>
              </div>

              <div
                style="display:flex; flex-direction: row;justify-content: space-around; "
              >
                <div style="margin:2px">
                  <v-btn
                    elevation="8"
                    v-if="videoBtnFlag"
                    style=" background: #FFFFFF;border-radius: 8px; color:white"
                    @click="switchCamera"
                    icon
                    ><img
                      src="https://s3iconimages.mymedicine.com.mm/switchCamera.svg"
                  /></v-btn>
                </div>
                <div style="margin:2px">
                  <v-btn
                    elevation="8"
                    v-if="videoBtnFlag"
                    style="  background: #FFFFFF;border-radius: 8px; color:white"
                    @click="turnVideoOff"
                    icon
                    ><img
                      src="https://s3iconimages.mymedicine.com.mm/CameraOn.svg"
                  /></v-btn>
                  <v-btn
                    elevation="8"
                    v-else
                    style="  background: #FFFFFF;border-radius: 8px; color:white"
                    @click="turnVideoOff"
                    icon
                    ><img
                      src="https://s3iconimages.mymedicine.com.mm/CameraOff.svg"
                  /></v-btn>
                </div>
                <div style="margin:2px">
                  <v-btn
                    elevation="8"
                    v-if="audioBtnFlag"
                    style="  background: #FFFFFF;border-radius: 8px; color:white"
                    @click="turnAudioOff"
                    icon
                    ><img src="https://s3iconimages.mymedicine.com.mm/micOn.svg"
                  /></v-btn>
                  <v-btn
                    elevation="8"
                    v-else
                    style="  background: #FFFFFF;border-radius: 8px; color:white"
                    @click="turnAudioOff"
                    icon
                    ><img src="https://s3iconimages.mymedicine.com.mm/micOff.svg"
                  /></v-btn>
                </div>
                <div style="margin:2px">
                  <v-btn
                    elevation="8"
                    id="disconnectBtn"
                    style="background: #EB5757; border-radius: 8px;"
                    @click="endVideoCall"
                    icon
                    ><img
                      src="https://s3iconimages.mymedicine.com.mm/leaveCall.svg"
                  /></v-btn>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-app>
  </div>
</template>

<script>
import axios from "axios";
import NavBar from "../../../views/navbar.vue";
export default {
  name: "CustomerVideoCall",
  components: {
    NavBar,
  },
  data() {
    return {
      auth_error_enable: false,
      error_enable: false,
      // booking_id:"",
      currentCustomer: "",
      customer_name: "",
      customer_id: "",
      appointment_id: "",
      appointment_url: "",
      countDownTimer: "",
      timerValue: "",
      appointmentObject: "",
      clearDistance: false,
      executeSecondTimer: false,
      isMobile: false,
      appointmentTimeOverModal: false,
      showTimer: true,
      appointmentStartMessageModal: false,
      isLoading: true,
      sessionId: "",
      sessionToken: "",
      publisherData: "",
      videoStatus: true,
      audioStatus: true,
      videoBtnFlag: true,
      audioBtnFlag: true,
      endcall: false,
      session: "",
      promiseFun: "",
      show_header:true,
      sessionLocalDetails:"",
      appLevelCheck: true,
      appLevelCheckModal: false,
    };
  },
  methods: {
    closeAppLevelCheckModal(){
      this.appLevelCheckModal=false
       this.$router.push({
        name: "CustomerHomeScreen",
      });
    },
    logEvent(event) {
      // console.log("Meeting Left");
      if (event == "leave") {
        var leaveLogEventBody = {
          bookingId: this.appointment_id,
          userType: "CUSTOMER",
          token: this.currentCustomer,
          typeOfUser: "CUSTOMER",
        };
        console.log("[leaveLogEventBody]",leaveLogEventBody);
        axios
          .post(
            process.env.VUE_APP_BACKEND_URL + "/exitCallLogEntry",
            leaveLogEventBody
          )
          .then((exitCallLogEntryResponse) => {
            console.log("[Exit Call Event Response]", exitCallLogEntryResponse);
          })
          .catch((exitCallLogEntryError) => {
            console.log("[Exit Call Event error]", exitCallLogEntryError);
          });
        this.$router.push({
          path: "/customer/feedback/" + this.appointment_id,
        });
      }
    },
    joinLogEvent(event) {
      console.log("Meeting Joined", this.appointment_id);
      if (event === "join") {
        var JoinLogEventBody = {
          bookingId: this.appointment_id,
          userType: "CUSTOMER",
          token: this.currentCustomer,
          typeOfUser: "CUSTOMER",
        };
        console.log("[Join Log event]", JoinLogEventBody);
        axios
          .post(
            process.env.VUE_APP_BACKEND_URL + "/joinCallEntry",
            JoinLogEventBody
          )
          .then((joinCallEntryResponse) => {
            console.log("[Join Call Event Response]", joinCallEntryResponse);
          })
          .catch((joinCallEntryError) => {
            console.log("[Join Call Event Error]", joinCallEntryError);
          });
      }
    },
    sleep(booking_id, token, router) {
      setTimeout(function() {
        var changeAppointmentStatusOnCompletiondata = {
          bookingId: this.appointment_id,
          token: token,
          typeOfUser: "CUSTOMER",
        };
        axios
          .post(
            process.env.VUE_APP_BACKEND_URL +
              "/changeAppointmentStatusOnCompletion",
            changeAppointmentStatusOnCompletiondata
          )
          .then((changeAppointmentStatusOnCompletionSuccess) => {
            router.push({
              path: "/customer/feedback/" + booking_id,
            });
          })
          .catch((changeAppointmentStatusOnCompletionError) => {
            router.push({
              path: "/customer/feedback/" + booking_id,
            });
          });
      }, 5000);
    },
    redirectToHomePage() {
      this.$router.push({
        name: "CustomerHomeScreen",
      });
    },
    closeDialogAppointmentStartMessageModal() {
      this.$router.push({
        name: "CustomerHomeScreen",
      });
    },
    closeDialogAppointmentTimeOverModal() {
      this.appointmentTimeOverModal = false;
      this.$router.push({
        path: "/customer/feedback/" + this.appointment_id,
      });
    },
    closeDialogUnauthorized() {
      this.auth_error_enable = false;
      this.$router.push({
        name: "Login",
      });
    },
    closeDialogError() {
      this.error_enable = false;
    },
    timerOne() {
      var startTime = new Date(
        this.appointmentObject.booking_epoch_time
      ).getTime();
      var currentTime = new Date().getTime();
      // console.log(startTime, currentTime);
      if (startTime - currentTime > 600000) {
        this.showTimer = true;
        // this.countDownTimer = "";
      }
      if (this.showTimer) {
        var countDownDate = new Date(this.appointmentObject.booking_closing_epoch_time).getTime();
        var today = new Date(this.appointmentObject.current_epoch_time).getTime();
        var intervalOne = setInterval(() => {
          today = today + 1000
          var distance = countDownDate - today;
          var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          var seconds = Math.floor((distance % (1000 * 60)) / 1000);
          this.countDownTimer = minutes + ":" + seconds;
          if (distance < 0) {
            clearInterval(intervalOne);
            this.countDownTimer = "00:00";
            this.appointmentTimeOverModal = true;
            this.sleep(this.appointment_id, this.currentCustomer, this.$router);
            // this.timerTwo();
          }
        }, 1000);
      } else {
        this.appointmentStartMessageModal = true;
      }
    },
    timerTwo() {
      // this.executeSecondTimer = true;
      var endTime = this.appointmentObject.time_slot.split("-")[1];
      var bufferTime = Number(endTime.split(":")[1]) + 3;
      var combinedTime = endTime.split(":")[0] + ":" + bufferTime;
      var countDownDate = new Date(
        this.appointmentObject.date_of_appointment +
          " " +
          String(combinedTime) +
          ":00 GMT+0630"
      ).getTime();
      //console.log("bufferTime ",bufferTime)
      // console.log("countDownDate ",countDownDate)
      // console.log("Inside second timer");
      var today = new Date().getTime();
      var distance = countDownDate - today;
      var intervalTwo = setInterval(() => {
        // console.log("distance : ",distance);
        distance = distance - 1000;
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);
        this.countDownTimer = minutes + ":" + seconds;
        if (distance < 0) {
          clearInterval(intervalTwo);
          this.countDownTimer = "00:00";
          this.appointmentTimeOverModal = true;
          this.executeSecondTimer = false;
          this.endVideoCall()
        }
      }, 1000);
    },
    handleError(error) {
      if (error) {
        alert(error.message);
      }
    },
    initializeSession(sessionId) {
      this.sessionDetails = "";
      // console.log("[Session intialized]");
      var sessionDetails = OT.initSession(process.env.VUE_APP_OPEN_TOK_API_KEY, this.sessionId);
      this.sessionLocalDetails = sessionDetails
      sessionDetails.on("streamCreated", function(event) {
        sessionDetails.subscribe(
          event.stream,
          "subscriber",
          {
            insertMode: "append",
            width: "95%",
            height: "100vh",
          },
          this.handleError
        );
      });
      var publisher = OT.initPublisher(
        "publisher",
        {
          insertMode: "append",
          width: "106px",
          height: "120px",
          buttonDisplayMode: "on",
          autoGainControl: true,
        },
        this.handleError
      );
      this.publisherData = publisher;
      //console.log(this.publisherData)
      sessionDetails.connect(this.sessionToken, function(error) {
        if (error) {
          this.handleError(error);
        } else {
          sessionDetails.publish(publisher);
        }
      });
      this.joinLogEvent("join")
    },
    endVideoCall() {
      //var sessionDetails = OT.initSession(process.env.VUE_APP_OPEN_TOK_API_KEY, this.sessionId);
      this.sessionLocalDetails.disconnect();
      // console.log("Call ended")
      this.logEvent("leave")
    },
    turnVideoOff() {
      var videodata = this.videoStatus == true ? false : true;
      this.videoStatus = videodata;
      this.publisherData.publishVideo(videodata);
      this.videoBtnFlag = videodata;
    },
    turnAudioOff() {
      var audiodata = this.audioStatus == true ? false : true;
      this.audioStatus = audiodata;
      this.publisherData.publishAudio(audiodata);
      this.audioBtnFlag = audiodata;
    },
    switchCamera() {
      this.publisherData
        .cycleVideo()
        .then((deviceID) => setVideoSource(deviceID));
    },
  },

  beforeRouteLeave: function(to, from, next) {
    from = this.sessionId;
    //var sessionDetails = OT.initSession(process.env.VUE_APP_OPEN_TOK_API_KEY, from);
    this.sessionLocalDetails.unpublish(this.publisherData);
    // alert("session Disconnected")
    next();
  },
  mounted() {
// navigation testing starts
          const events = [
            "pagehide",
            "unload"
            ];
      const eventLogger = event => {
              switch (event.type) {
              case "pagehide":
              console.log("back button");
              this.endVideoCall()
              console.log('Event:', event.type);
              break;
              default:
                console.log(' Default Event:', event.type);
              break;
          }
        };
      events.forEach(eventName =>
          window.addEventListener(eventName, eventLogger)
        );
// navigation testing ends
var userAgent = navigator.userAgent
    // var userAgent = "Mozilla/5.0 (Linux; Android 9; MI 6X Build/PKQ1.180904.001; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/76.0.3809.111 Mobile Safari/537.36; kbzpay app/customer platform/android lang/my unicode/true version/5.0.3"
    this.$i18n.locale =  this.$store.state.locale;
    window.addEventListener('popstate',  console.log("navigation type",PerformanceNavigationTiming.type));
    console.log("navigation type",performance.navigation.type)
    if(userAgent.includes("kbzpay")){
      this.show_header = false;
    }
    this.isMobile = this.$device.mobile;
    //document.title = "Video Consultation";
    if(this.$store.state.locale == "en")
      document.title = "Video Consultation"
    else
      document.title = "ဗီဒီယိုဖြင့်တိုင်ပင်ဆွေးနွေးခြင်း";

    if(userAgent.includes("kbzpay")){
      var slicedData = userAgent.slice(userAgent.indexOf("kbzpay")).split('/')
      if(slicedData[slicedData.length-1] < "5.2.3"){
       this.appLevelCheck = false
       this.appLevelCheckModal = true
      }
      else
      {
        this.executeSecondTimer = false;
        this.currentCustomer = this.$cookies.get("customerToken");
        var access = this.$cookies.get("access");
        if (!this.currentCustomer) {
          this.$router.push({
            name: "Login",
          });
        } else {
          this.appointment_id = this.$route.params.booking_id;
          // console.log(this.appointment_id);
          var viewParticularAppointmentPageData = {
            token: this.currentCustomer,
            bookingId: this.appointment_id,
            typeOfUser: "CUSTOMER",
          };
          axios
            .post(
              process.env.VUE_APP_BACKEND_URL + "/videoCallPageTokBox",
              viewParticularAppointmentPageData
            )
            .then((viewParticularAppointmentPageResponse) => {
              // console.log("[Response]",viewParticularAppointmentPageResponse.data )
              this.customer_id =
                viewParticularAppointmentPageResponse.data.customer._id;
              this.customer_name =
                viewParticularAppointmentPageResponse.data.customer.customer_name;
              this.appointmentObject =
                viewParticularAppointmentPageResponse.data.data;
              // this.appointment_url = viewParticularAppointmentPageResponse.data.data.meeting_link
              this.sessionId =
                viewParticularAppointmentPageResponse.data.data.session_id;
              this.sessionToken =
                viewParticularAppointmentPageResponse.data.data.session_token;
              this.initializeSession(this.sessionId);
              this.timerOne();
              this.isLoading = false;
              var user_config_body = {
                appointment_reference_number: this.appointmentObject.videoCallPage,
                appointment_id: this.appointmentObject._id,
                meeting_link: this.appointmentObject.meeting_link,
                config_type: "VIDEO_CALL",
                user_type: "CUSTOMER",
                userId: this.customer_id,
                user_name: this.customer_name,
              };

              // User config call removed.
              // axios
              //   .post(
              //     process.env.VUE_APP_BACKEND_URL + "/createUserConfigTokBox",
              //     user_config_body
              //   )
              //   .then((createUserConfigResponse) => {
              //   })
              //   .catch((createuserconfigError) => {
              //     console.log(createuserconfigError);
              //   });

            })
            .catch((viewParticularAppointmentPageError) => {
              console.log(viewParticularAppointmentPageError);
              if (viewParticularAppointmentPageError.response.status == 401) {
                this.auth_error_enable = true;
              } else {
                this.error_enable = true;
              }
            });
        }
      }
    }
    else
    {
      this.executeSecondTimer = false;
      this.currentCustomer = this.$cookies.get("customerToken");
      var access = this.$cookies.get("access");
      if (!this.currentCustomer) {
        this.$router.push({
          name: "Login",
        });
      } else {
        this.appointment_id = this.$route.params.booking_id;
        // console.log(this.appointment_id);
        var viewParticularAppointmentPageData = {
          token: this.currentCustomer,
          bookingId: this.appointment_id,
          typeOfUser: "CUSTOMER",
        };
        axios
          .post(
            process.env.VUE_APP_BACKEND_URL + "/videoCallPageTokBox",
            viewParticularAppointmentPageData
          )
          .then((viewParticularAppointmentPageResponse) => {
            // console.log("[Response]",viewParticularAppointmentPageResponse.data )
            this.customer_id =
              viewParticularAppointmentPageResponse.data.customer._id;
            this.customer_name =
              viewParticularAppointmentPageResponse.data.customer.customer_name;
            this.appointmentObject =
              viewParticularAppointmentPageResponse.data.data;
            // this.appointment_url = viewParticularAppointmentPageResponse.data.data.meeting_link
            this.sessionId =
              viewParticularAppointmentPageResponse.data.data.session_id;
            this.sessionToken =
              viewParticularAppointmentPageResponse.data.data.session_token;
            this.initializeSession(this.sessionId);
            this.timerOne();
            this.isLoading = false;
            var user_config_body = {
              appointment_reference_number: this.appointmentObject.videoCallPage,
              appointment_id: this.appointmentObject._id,
              meeting_link: this.appointmentObject.meeting_link,
              config_type: "VIDEO_CALL",
              user_type: "CUSTOMER",
              userId: this.customer_id,
              user_name: this.customer_name,
            };

            // User config call removed.
            // axios
            //   .post(
            //     process.env.VUE_APP_BACKEND_URL + "/createUserConfigTokBox",
            //     user_config_body
            //   )
            //   .then((createUserConfigResponse) => {
            //   })
            //   .catch((createuserconfigError) => {
            //     console.log(createuserconfigError);
            //   });
            
          })
          .catch((viewParticularAppointmentPageError) => {
            console.log(viewParticularAppointmentPageError);
            if (viewParticularAppointmentPageError.response.status == 401) {
              this.auth_error_enable = true;
            } else {
              this.error_enable = true;
            }
          });
      }
    }
  },
};
</script>

<style scoped>
.timerClass {
  position: absolute;
  margin-top: 20px;
  font-size: 150%;
}
whereby-embed {
  overflow: hidden;
  height: 90vh;
  width: 100%;
}
.pub_class {
  position: absolute;
  z-index: 999;
  bottom: 10%;
  right: 2%;
  margin: 5%;
}
.sub_class {
  position: relative;
  z-index: 0;
  width: 95%;
  height: 80vh;
  margin: 5% 5%;
}
.timmerText {
  position: absolute;
  border: 2px;
  background-color: #010101;
  color: white;
  padding: 5px;
  z-index: 999;
  opacity: 0.5;
  border-radius: 0px 0px 0px 4px;
  font-size: 20px;
}
</style>
